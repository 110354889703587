.link-tree-buttons {
  width: 100%;
  margin-top: 10px;
  & a {
    border-radius: 30px;
    background-color: white;
    margin-bottom: 20px;
    height: 35px;
    font-variant-caps: petite-caps;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration-line: none;
    font-weight: 700;
    color: black;

    &:hover {
      background-color: #979696;
    }
  }
}
