.link-tree {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .center {
    max-width: 600px;
    width: 86%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .telephone {
      color: white;
      margin-bottom: 1rem;
      & a {
        color: white;
      }
    }

    & .fondosEuropeos {
      width: 100%;
      border-radius: 20px;
      border-style: none;
    }
  }
}
