.logo {
  & > div {
    background-image: url("../images/linktreeLogo.jpg");
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    transform: scale(0.9);
    filter: invert(1);
  }
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  margin: 15px;
}
