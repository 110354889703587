.page {
  box-shadow: 1px 1px 5px 0 rgba(74, 141, 211, 0.4);
  margin: 1em 0 1rem;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
